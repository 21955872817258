import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import backgroundAbout from '../../Assets/header-bg(3).jpg';
import Articles from '../../Components/Articles/Articles';
import './Blogs.scss'

const Blogs = () => {

    const [videoWidth, setVideoWidth] = useState('100%');
    const [showFullVideo, setShowFullVideo] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > window.innerHeight * 0.3) {
                setVideoWidth('70%');
                setShowFullVideo(false);
            } else {
                setVideoWidth('100%');
                setShowFullVideo(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div
                className={`relative flex flex-col items-center justify-center transition-all duration-500`}
                style={{ width: videoWidth, margin: '0 auto 10% auto' }}
            >
                <img src={backgroundAbout} className='absolute z-[-1] rounded-[10px] bg-image' />
                <div className='flex flex-col items-center justify-center p-[20%] gap-5'>
                    <Typography sx={{
                        fontFamily: "Poppins",
                        fontSize: '120px',
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        lineHeight: '105px',
                        letterSpacing: '-5px',
                        color: '#FFF',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }} className='heading-text'>
                        BLOGS
                    </Typography>
                </div>
            </div>

            <Articles />
        </>
    )
}

export default Blogs
