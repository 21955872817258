import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import articleImage from '../../Assets/InnovistaContent8-min.png';
import articleImage2 from '../../Assets/InnovistaContent7-min.png';
import articleImage3 from '../../Assets/InnovistaContent6-min.png';
import blogImage from '../../Assets/19.png';
import dinner from '../../Assets/dinner-thumbnail.png'
import openhouse from '../../Assets/openhouse-thumbnail.png'
import inauguration from '../../Assets/inaugration-thumbnail.png'
import exploring from '../../Assets/exploring-thumbnail.png'
import './Articles.scss';

const Articles = () => {
    const articles = [
        {
            id: 1, // Add unique IDs
            image: inauguration,
            date: '7th September, 2024',
            headLine: 'Inauguration of InnoVista Tech Zone-Ravi: A New Era for IT Innovation in Pakistan',
            firstButton: 'Future',
            secondButton: 'Worker',
        },
        {
            id: 2, // Add unique IDs
            image: openhouse,
            date: '31 August, 2024',
            headLine: 'InnoVista Open House 2024: Connecting IT Innovators, Freelancers, and Startups',
            firstButton: 'Future',
            secondButton: 'Worker',
        },
        {
            id: 3, // Add unique IDs
            image: dinner,
            date: '24 August, 2024',
            headLine: 'InnoVista Networking Dinner: Uniting Pakistan’s IT Leaders and Visionaries',
            firstButton: 'Future',
            secondButton: 'Worker',
        },
        {
            id: 4, // Add unique IDs
            image: exploring,
            date: '24 August, 2024',
            headLine: 'Exploring the Future of E-commerce: Highlights from "New Digital Trends in E-commerce" at InnoVista Ravi!',
            firstButton: 'Future',
            secondButton: 'Worker',
        },
    ];

    return (
        <Box className='px-[10%] py-[5%] flex flex-col items-start gap-[4rem] articles-main'>
            <div className='flex items-center justify-between w-full articles-heading'>
                <Box className='flex flex-col justify-between items-start'>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            fontSize: '15px',
                            color: '#fff',
                            letterSpacing: '4px',
                        }}
                    >
                        BLOG NEWS
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            fontSize: '56px',
                            color: '#fff',
                            lineHeight: '1.2em',
                        }}
                        className='main-header'
                    >
                        Latest Articles From InnoVista
                    </Typography>
                </Box>
                <Box className='flex flex-col justify-between items-start'>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#F02D27',
                            color: '#fff',
                            fontWeight: 'bold',
                            transition: 'opacity 0.6s ease',
                            '&:hover': {
                                color: '#fff',
                                background: '#F02D27',
                            },
                        }}
                    >
                        <a href='/Blogs' className='learn-more'>
                            View More →
                        </a>
                    </Button>
                </Box>
            </div>
            <Box className='grid grid-cols-4 gap-4 articles-grid'>
                {articles.map((article) => (
                    <div className='flex flex-col items-start justify-start gap-2' key={article.id}>
                        <Box className='relative'>
                            <a href={`/article/${article.id}`} target="_blank" rel="noopener noreferrer">
                                <img src={article.image} alt='' className='rounded-[10px]' />
                            </a>
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: '600',
                                fontSize: '14px',
                                color: '#fff',
                            }}
                        >
                            {article.date}
                        </Typography>
                        <a href={`/article/${article.id}`} target="_blank" rel="noopener noreferrer">
                            <Typography
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '500',
                                    fontSize: '21px',
                                    color: '#fff',
                                    lineHeight: '1.5em',
                                }}
                            >
                                {article.headLine}
                            </Typography>
                        </a>

                    </div>
                ))}
            </Box>
        </Box>
    );
};

export default Articles;
